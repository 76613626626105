import * as React from "react"
import LoginHeader from "../LoginHeader"
import ChipsContainer from "../ChipsContainer"
import Image from "../../atoms/Image"
import { IPlatformCard } from "../../molecules/Card"
import { IPlatformCardProps } from "../../molecules/Card/IPlatformCard"
import Text from "../../atoms/Text"
import ListAddIcon from "../../molecules/ListComp/ListAddIcon"
import { useAppListContext } from "../../../Context/AppListContext"
import BuyCreditCard from "../../atoms/svg/BuyCreditCard"
import { IProductRec, useIPlatformContext } from "../../../Context/IPlatformContext"
import Video from "../../molecules/Video";
import CarouselComp from "../../molecules/Carousel";
import CloseIcon from "../../atoms/svg/CloseIcon";
import PortableTextRender from '../../molecules/PortableTextRender';


const addSpacing = (value: Boolean[]): Boolean => {
  if(!value?.[0]) return true;
  return false;
}

const IplatformCardView: React.FC<{ id: string }> = ({ id }) => {
  const { getProductByID } = useIPlatformContext()
  const cardProps: IPlatformCardProps & IProductRec = getProductByID?.(id);
  const { onAppListClick, isInAppList } = useAppListContext();
  const handleClick = () => {
    cardProps?.uniqueReference && 
    !isInAppList?.(cardProps?.uniqueReference) && 
    onAppListClick && onAppListClick(cardProps as IPlatformCardProps)
  }

  const LabelTextComp = ({
    label,
    info,
    children,
  }: {
    label: string
    info?: string
    children?: any
  }) => (
    <>
      {(children || info) && (
        <>
          <Text type="P" ptype="sm-bold" className="text-viking">
            {label}
          </Text>
          {info && (
            <Text type="P" ptype="sm-light" className="mb-8">
              {info}
            </Text>
          )}
          {children}
        </>
      )}
    </>
  )

  const [viewImg, setViewImg] = React.useState(false);

  const enlargeImage = () => {
    !viewImg && setViewImg(true)
  };
  const closeImage = () => viewImg && setViewImg(false);

  return (
    <div>
      <LoginHeader />
      <ChipsContainer showFilter={false} />
      <div className="max-w-7xl py-12 mx-auto flex px-4 flex-col md:flex-row">
          <div className="card sm:min-w-[350px] min-w-full">
              <IPlatformCard {...cardProps} className="w-full" readMoreNotVisible />
          </div>
          <div className="card-info px-8 flex-grow">
              <Text type="H2" className="my-0 py-0" >{cardProps?.title}</Text>
              <LabelTextComp label="Solutions Provider" info={cardProps?.provider?.name} />

              <LabelTextComp label="Detailed Description">
              {cardProps?.detail?.length && <PortableTextRender className="" content={cardProps?.detail} template={{
                normal: (props: any) => <Text type="P" ptype="p-base" 
                className={`sm-light ${addSpacing(props?.children?.map?.(Boolean)) ? 'my-8': 'm-0'}`}
                  >{props.children}</Text>,
                ListRowrow: (props: any) => {
                  return <ul className="dash-style py-2">
                    <li className='sm-light border-b mb-1 pl-2'>
                    {props.children}
                  </li>
                  </ul>
                }
              }} />}
              </LabelTextComp>
              <LabelTextComp label="Territories" info={cardProps?.territory?.[0]?.title} />
              <LabelTextComp label="PAS Pre-Integrated" info={cardProps?.PASIntegrated} />
              <LabelTextComp label="PAS Independent" info={cardProps?.pasIndependent ? "Yes" : "No"} />
              <LabelTextComp label="Billing Methodology" info={cardProps?.billingMethodology} />
              {cardProps?.video?.url && <div className="video-render w-full max-w-2xl mx-0 my-10">
                <Video
                  title={cardProps?.video?.title || cardProps?.title || "A sample video"} 
                  videoUrl={cardProps?.video?.url}
                  options={{
                    controls: true,
                  }}
                  className="lg:w-full md:w-full sm:w-full w-full"
                />
              </div>}
              {!!cardProps?.screenshots?.length &&
              <div className={viewImg ? "fixed flex items-center justify-center top-0 bottom-0 right-0 left-0 z-110": ""}>
                {viewImg && <div className="overlay top-0 bottom-0 right-0 left-0 absolute bg-modal-black z-90"></div>}
                {viewImg && <div onClick={closeImage} className="absolute top-10 right-10 z-100 cursor-pointer" ><CloseIcon /></div>}
               <CarouselComp onClick={enlargeImage} style={{
                 maxWidth: viewImg ? '90vw' : '42rem',
               }} className="my-4 w-full mx-0 z-100">
                  {cardProps?.screenshots?.map((rec, i) => {
                    return (
                      <Image onClick={enlargeImage} style={{maxHeight: viewImg ? '85vh': '24em'}} className="w-full cursor-pointer object-contain" key={i} src={rec?.asset?.url} alt="" />
                    )
                  })}
                </CarouselComp>
              </div>}
              {(cardProps?.contactName || cardProps?.contactEmail) && <>
                <Text type="P" ptype="sm-bold" className="text-viking" >Contact</Text>
                <Text type="P" ptype="sm-light" className="mb-0" >{cardProps?.contactName}</Text>
                <Text type="P" ptype="sm-light" className="mb-8" >{cardProps?.contactEmail}</Text>
              </>}
              <div className="image-gallery">

              </div>
              <div className="flex flex-wrap border border-l-0 border-r-0 border-t-1 border-b-1 solid border-light-grey pt-4 pb-8 mt-12 my-8 justify-between items-center">
                 <div className="flex mt-8 mb-4 mr-4">
                   <ListAddIcon {...cardProps} />
                   <button onClick={handleClick} type="button" className="bg-viking btn-base text-white rounded-3xl py-2 px-8 ml-6 whitespace-nowrap mx-auto w-90 text-xs sm:text-base hidden sm:block" >I'm interested - Add to my list</button>
                 </div>
                 <div className="relative opacity-60 mt-8 mb-4">
                 <Text type="P" ptype="sm-bold" className="text-viking absolute -top-8" >Coming Soon</Text>
                   <div className="flex">
                   <BuyCreditCard />
                   <button onClick={handleClick} type="button" className="bg-viking btn-base text-white rounded-3xl py-2 px-8 ml-6 whitespace-nowrap mx-auto w-90 text-xs sm:text-base" >Buy Credits</button>
                 </div>
                 </div>
                 
              </div>
          </div>
      </div>
    </div>
  )
}

export default IplatformCardView
