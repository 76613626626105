import React from 'react';


const BuyCreditCard: React.FC = () => {

  return (
    <svg width="56px" height="48px" viewBox="0 0 56 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/buy-credits</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group">
              <path d="M0,5.657136 C0,2.556912 2.467056,0 5.563632,0 L44.83632,0 C47.93304,0 50.4,2.556912 50.4,5.657136 L50.4,18 C50.4,18.66264 49.86264,19.2 49.2,19.2 C48.53736,19.2 48,18.66264 48,18 L48,5.657136 C48,3.834168 46.55976,2.4 44.83632,2.4 L5.563632,2.4 C3.840264,2.4 2.4,3.834168 2.4,5.657136 L2.4,27.94296 C2.4,29.76576 3.840264,31.2 5.563632,31.2 L22.8,31.2 C23.46264,31.2 24,31.73736 24,32.4 C24,33.06264 23.46264,33.6 22.8,33.6 L5.563632,33.6 C2.467056,33.6 0,31.04304 0,27.94296 L0,5.657136 Z" id="Path" fill="#404040"></path>
              <path d="M0,8.4 C0,7.737264 0.5372592,7.2 1.2,7.2 L49.2,7.2 C49.86264,7.2 50.4,7.737264 50.4,8.4 C50.4,9.062736 49.86264,9.6 49.2,9.6 L1.2,9.6 C0.5372592,9.6 0,9.062736 0,8.4 Z" id="Path" fill="#000000"></path>
              <path d="M4.8,20.4 C4.8,19.73736 5.337264,19.2 6,19.2 L10.8,19.2 C11.462736,19.2 12,19.73736 12,20.4 C12,21.06264 11.462736,21.6 10.8,21.6 L6,21.6 C5.337264,21.6 4.8,21.06264 4.8,20.4 Z" id="Path" fill="#404040"></path>
              <path d="M4.8,26.4 C4.8,25.73736 5.337264,25.2 6,25.2 L15.6,25.2 C16.262736,25.2 16.8,25.73736 16.8,26.4 C16.8,27.06264 16.262736,27.6 15.6,27.6 L6,27.6 C5.337264,27.6 4.8,27.06264 4.8,26.4 Z" id="Path" fill="#4CC2B8"></path>
              <path d="M42,24 C36.03528,24 31.2,28.83528 31.2,34.8 C31.2,40.76472 36.03528,45.6 42,45.6 C47.96472,45.6 52.8,40.76472 52.8,34.8 C52.8,28.83528 47.96472,24 42,24 Z M28.8,34.8 C28.8,27.50976 34.70976,21.6 42,21.6 C49.29024,21.6 55.2,27.50976 55.2,34.8 C55.2,42.09024 49.29024,48 42,48 C34.70976,48 28.8,42.09024 28.8,34.8 Z" id="Shape" fill="#4CC2B8"></path>
              <path d="M45.95136,32.75136 C46.42008,32.28288 47.17992,32.28288 47.64864,32.75136 C48.11712,33.22008 48.11712,33.97992 47.64864,34.44864 L42.84864,39.24864 C42.37992,39.71712 41.62008,39.71712 41.15136,39.24864 L37.55136,35.64864 C37.08288,35.17992 37.08288,34.42008 37.55136,33.95136 C38.02008,33.48288 38.77992,33.48288 39.24864,33.95136 L42,36.70296 L45.95136,32.75136 Z" id="Path" fill="#4CC2B8"></path>
          </g>
      </g>
    </svg>
  )
}

export default BuyCreditCard;
