import React from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './style.css';

interface Props {
  children: any;
  className: string;
  style?: any;
  onClick: any;
}

const CarouselComp = ({children, className, onClick, ...rest}: Props) => {
  return (
    <div className={className} {...rest}>
      <Carousel className="cursor-pointer" showThumbs={false} showIndicators={true} onClickItem={onClick} >
      {children}
     </Carousel>
    </div>
   
  )
}

export default CarouselComp
