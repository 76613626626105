import React, { useEffect } from 'react';
import { PageProp } from '../../common/types';
import Layout from '../../common/components/template/Layout';
import IPlatformCardView from '../../common/components/organism/IPlatform/card'
import { useGlobalStateContext } from '../../common/Context/app'

const Index: React.FC<PageProp> = ({ pageContext }) => {
  const id = pageContext?.id;
  const { setSiteConfig } = useGlobalStateContext();

  useEffect(() => {
    setSiteConfig && pageContext && setSiteConfig({
      payload: pageContext,
      type: 'SET'
    })
  }, [pageContext]);

  return (
    <Layout>
      {id && <IPlatformCardView id={id} />}
    </Layout>
  )
};

export default Index;
